import React, { Component } from "react";

import ScrollupSection from "../../Scrollup/Scrollup";
import Header from "../../Header/HeaderThree";
import FooterSection from "../../Footer/FooterOne";
import ModalMenuSection from "../../Modal/ModalMenu";
import BreadcrumbSection from "../../Breadcrumb/BreadcrumbOne";

class Terms extends Component {
  render() {
    return (
      <div className="homepage-4">
        <ScrollupSection />
        <div className="main overflow-hidden">
          <Header
            logo1={"/img/logo/logo-white.svg"}
            logo2={"/img/logo/logo.png"}
          />

          <section className="section breadcrumb-area overlay-dark d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* Breamcrumb Content */}
                  <div className="breadcrumb-content d-flex flex-column align-items-center text-center">
                    <h2 className="text-white text-uppercase mb-3">
                      {"TERMS AND CONDITIONS"}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section d-flex align-items-center">
            <div className="container header mt-5">
              <div>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.45600128173828125pt",
                    marginRight: "20.4979248046875pt",
                    textIndent: "-0.048000335693359375pt",
                    marginTop: "21.9959716796875pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 0.048000335693359375pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    These terms and conditions (the "Terms and Conditions")
                    govern the use of&nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    www.digicheck.ca&nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    (the "Site"). This Site is owned and operated by Akshay
                    Patel. This Site is a our platform provides a comprehensive
                    solution for recording inspection data digitally, saving it
                    securely on the cloud, and exporting it.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.34799957275390625pt",
                    marginRight: "24.94989013671875pt",
                    textIndent: "-0.22800064086914062pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 0.22800064086914062pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    By using this Site, you indicate that you have read and
                    understand these Terms and Conditions and agree to abide by
                    them at all times.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.444000244140625pt",
                    marginRight: "30.13385009765625pt",
                    textIndent: "0.060001373291015625pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION
                    CLAUSE THAT IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE
                    DISPUTES. PLEASE READ IT CAREFULLY.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.384002685546875pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Intellectual Property&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.24000167846679688pt",
                    marginRight: "23.329833984375pt",
                    textIndent: "-0.3120002746582031pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 0.3120002746582031pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    All content published and made available on our Site is the
                    property of Akshay Patel and the Site's creators. This
                    includes, but is not limited to images, text, logos,
                    documents, downloadable files and anything that contributes
                    to the composition of our Site.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.24000167846679688pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Accounts&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.2999992370605469pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    When you create an account on our Site, you agree to the
                    following:&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "1.5480003356933594pt",
                    marginRight: "37.27386474609375pt",
                    textAlign: "center",
                    marginTop: "21.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    1. You are solely responsible for your account and the
                    security and privacy of your account, including passwords or
                    sensitive information attached to that account; and&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.3959999084472656pt",
                    marginRight: "25.23779296875pt",
                    textAlign: "center",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    2. All personal information you provide to us through your
                    account is up to date, accurate, and truthful and that you
                    will update your personal information if it changes.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.24000167846679688pt",
                    marginRight: "16.35784912109375pt",
                    textIndent: "0.05999755859375pt",
                    marginTop: "19.001998901367188pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We reserve the right to suspend or terminate your account if
                    you are using our Site illegally or if you violate these
                    Terms and Conditions.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.7680015563964844pt",
                    marginTop: "19.001998901367188pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Sale of Services&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "2.9988000869750975",
                    marginLeft: "0.5040016174316406pt",
                    marginRight: "126.26589965820312pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    These Terms and Conditions govern the sale of services
                    available on our Site. The following services are available
                    on our Site:&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginRight: "2.4219970703125pt",
                    textAlign: "right",
                    marginTop: "23.00799560546875pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Page 1 of 4
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    textAlign: "center",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "italic",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Website Terms and Conditions&nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Page 2 of 4
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.6360015869140625pt",
                    marginRight: "2.641845703125pt",
                    textIndent: "-33.09599685668945pt",
                    marginTop: "24.7459716796875pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 33.09599685668945pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    - Our platform provides a comprehensive solution for
                    recording inspection data digitally, saving it securely on
                    the cloud, and exporting it.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.5040016174316406pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The services will be paid for in full when the services are
                    ordered.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.12000274658203125pt",
                    marginRight: "4.2978515625pt",
                    textIndent: "0.3839988708496094pt",
                    marginTop: "21.9959716796875pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    These Terms and Conditions apply to all the services that
                    are displayed on our Site at the time you access it. All
                    information, descriptions, or images that we provide about
                    our services are as accurate as possible. However, we are
                    not legally bound by such information, descriptions, or
                    images as we cannot guarantee the accuracy of all services
                    we provide. You agree to purchase services from our Site at
                    your own risk.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.21599960327148438pt",
                    marginRight: "1.17779541015625pt",
                    textIndent: "0.0839996337890625pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We reserve the right to modify, reject or cancel your order
                    whenever it becomes necessary. If we cancel your order and
                    have already processed your payment, we will give you a
                    refund equal to the amount you paid. You agree that it is
                    your responsibility to monitor your payment instrument to
                    verify receipt of any refund.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.7680015563964844pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Subscriptions&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.2520027160644531pt",
                    marginRight: "8.66583251953125pt",
                    textIndent: "-0.32399749755859375pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 0.32399749755859375pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Your subscription does not automatically renew. You will be
                    notified before your next payment is due and must authorize
                    that payment in order for your subscription to
                    continue.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.4080009460449219pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Free Trial&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.21599960327148438pt",
                    marginRight: "7.4298095703125pt",
                    textIndent: "0.0839996337890625pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We offer the following free trial of our services: A 7 day
                    free trial that begins when users register for a new
                    account. The free trial includes unlimited access to all
                    documents available on our site.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.24000167846679688pt",
                    marginRight: "18.08575439453125pt",
                    textIndent: "-0.3719978332519531pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 0.3719978332519531pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    At the end of your free trial, the following will occur:
                    Account manager will schedule call to discuss further
                    actions.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.5040016174316406pt",
                    marginTop: "19.001998901367188pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    To cancel your free trial, please follow these steps:
                    Contact account manager to cancel free trial.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.444000244140625pt",
                    marginTop: "21.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Payments&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.2999992370605469pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We accept the following payment methods on our Site:&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.6360015869140625pt",
                    marginTop: "21.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    - Debit.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.2999992370605469pt",
                    marginTop: "21.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    When you provide us with your payment information, you
                    authorize our use of and access to the&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    textAlign: "center",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "italic",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Website Terms and Conditions&nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Page 3 of 4
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.12000274658203125pt",
                    marginRight: "29.20989990234375pt",
                    textIndent: "-0.4559974670410156pt",
                    marginTop: "24.7459716796875pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 0.4559974670410156pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    payment instrument you have chosen to use. By providing us
                    with your payment information, you authorize us to charge
                    the amount due to this payment instrument.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.444000244140625pt",
                    marginRight: "3.061767578125pt",
                    textIndent: "-0.108001708984375pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 0.108001708984375pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If we believe your payment has violated any law or these
                    Terms and Conditions, we reserve the right to cancel or
                    reverse your transaction.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.6240005493164062pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Consumer Protection Law&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.12000274658203125pt",
                    marginRight: "12.0499267578125pt",
                    textIndent: "0.17999649047851562pt",
                    marginTop: "3.9959716796875pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Where the&nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "italic",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Consumer Protection Act
                  </span>
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    , or any other consumer protection legislation in your
                    jurisdiction applies and cannot be excluded, these Terms and
                    Conditions will not limit your legal rights and remedies
                    under that legislation. These Terms and Conditions will be
                    read subject to the mandatory provisions of that
                    legislation. If there is a conflict between these Terms and
                    Conditions and that legislation, the mandatory provisions of
                    the legislation will apply.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.37200164794921875pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Limitation of Liability&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.21600341796875pt",
                    marginRight: "0.44580078125pt",
                    textIndent: "-0.023998260498046875pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 0.023998260498046875pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Akshay Patel and our directors, officers, agents, employees,
                    subsidiaries, and affiliates will not be liable for any
                    actions, claims, losses, damages, liabilities and expenses
                    including legal fees from your use of the Site.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.384002685546875pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Indemnity&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.24000167846679688pt",
                    marginRight: "6.92584228515625pt",
                    textIndent: "0.15599822998046875pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Except where prohibited by law, by using this Site you
                    indemnify and hold harmless Akshay Patel and our directors,
                    officers, agents, employees, subsidiaries, and affiliates
                    from any actions, claims, losses, damages, liabilities and
                    expenses including legal fees arising out of your use of our
                    Site or your violation of these Terms and Conditions.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.24000167846679688pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Applicable Law&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.5040016174316406pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    These Terms and Conditions are governed by the laws of the
                    Province of Ontario.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.2999992370605469pt",
                    marginTop: "21.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Dispute Resolution&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.12000274658203125pt",
                    marginRight: "0.96185302734375pt",
                    textIndent: "0.779998779296875pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Subject to any exceptions specified in these Terms and
                    Conditions, if you and Akshay Patel are unable to resolve
                    any dispute through informal discussion, then you and Akshay
                    Patel agree to submit the issue before a mediator. The
                    decision of the mediator will not be binding. Any mediator
                    must be a neutral party acceptable to both you and Akshay
                    Patel. The costs of any mediation will be shared equally
                    between you and Akshay Patel.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginTop: "19.001998901367188pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Notwithstanding any other provision in these Terms and
                    Conditions, you and Akshay Patel agree that&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    textAlign: "center",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "italic",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Website Terms and Conditions&nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Page 4 of 4
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.21599960327148438pt",
                    marginRight: "17.71380615234375pt",
                    marginTop: "24.7459716796875pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    you both retain the right to bring an action in small claims
                    court and to bring an action for injunctive relief or
                    intellectual property infringement. &nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.7680015563964844pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Severability&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.444000244140625pt",
                    marginRight: "3.6378173828125pt",
                    textIndent: "-0.060001373291015625pt",
                    marginTop: "3.9959716796875pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 0.060001373291015625pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    If at any time any of the provisions set forth in these
                    Terms and Conditions are found to be inconsistent or invalid
                    under applicable laws, those provisions will be deemed void
                    and will be removed from these Terms and Conditions. All
                    other provisions will not be affected by the removal and the
                    rest of these Terms and Conditions will still be considered
                    valid.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.6240005493164062pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Changes&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.4994000434875487",
                    marginLeft: "0.13199996948242188pt",
                    marginRight: "9.433837890625pt",
                    textIndent: "0.37200164794921875pt",
                    marginTop: "3.9959716796875pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    These Terms and Conditions may be amended from time to time
                    in order to maintain compliance with the law and to reflect
                    any changes to the way we operate our Site and the way we
                    expect users to behave on our Site. We will notify users by
                    email of changes to these Terms and Conditions or post a
                    notice on our Site.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.6240005493164062pt",
                    marginTop: "19.00201416015625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Contact Details&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.34799957275390625pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Please contact us if you have any questions or concerns. Our
                    contact details are as follows:&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.6360015869140625pt",
                    marginTop: "21.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    (204) 963-2544&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.4920005798339844pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    info@digicheck.ca&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.3959999084472656pt",
                    marginTop: "3.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  ></span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    marginLeft: "0.2520027160644531pt",
                    marginTop: "21.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    You can also contact us through the feedback form available
                    on our Site.&nbsp;
                  </span>
                </p>
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.2",
                    textAlign: "right",
                    marginTop: "21.996002197265625pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Effective Date: 13th day of May, 2023&nbsp;
                  </span>
                </p>
              </div>
            </div>
          </section>
          <FooterSection />
          {/* <ModalSearchSection /> */}
          <ModalMenuSection />
        </div>
      </div>
    );
  }
}

export default Terms;
