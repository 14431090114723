import React, { Component } from "react";
import BlogSingleTwo from "../Blogs/BlogSingleTwo";
const initData = {
  heading: "Bringing inspections to the next generation",
  headingText_1:
    "We serve manufacturing, services, energy, healthcare, oil and gas, construction, and transportation/warehousing clients.",
  headingText_2: "",
};

const data = [
  {
    id: 1,
    image: "/img/custom/industries/1.jpg",
  },
  {
    id: 2,
    image: "/img/custom/industries/2.jpg",
  },
  {
    id: 3,
    image: "/img/custom/industries/3.jpg",
  },
  {
    id: 4,
    image: "/img/custom/industries/4.jpg",
  },
  {
    id: 5,
    image: "/img/custom/industries/5.jpg",
  },
];

class Branding extends Component {
  state = {
    initData: {},
    data: [],
  };
  componentDidMount() {
    this.setState({
      initData: initData,
      data: data,
    });
  }
  render() {
    return (
      <section className="section brand-partner ptb_100" id="about">
        <div className="container">
          <div
            className="row justify-content-center"
            data-aos="fade-left"
            data-aos-delay={0}
          >
            <div className="col-12 col-md-10 col-lg-7">
              {/* Section Heading */}
              <div className="section-heading text-center mb-2">
                <h2>{this.props.initData.heading}</h2>
                <p className="d-none d-sm-block mt-4">
                  {this.props.initData.headingText_1}
                </p>
                <p className="d-block d-sm-none mt-4">
                  {this.props.initData.headingText_2}
                </p>
              </div>
            </div>
          </div>
          <div className="container" data-aos="fade-right" data-aos-delay={0}>
            <BlogSingleTwo data={this.props.data} />
          </div>
        </div>
      </section>
    );
  }
}

export default Branding;
