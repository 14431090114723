import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOneContactSection";

class ContactOne extends Component {
    state = {
        data: {},
        contactData: [],
        name: '',
        company_name: '',
        email: '',
        phone: '',
        message: '',
        btnText: 'Submit',
        loading: false,
        res: ''
    }
    componentDidMount(){
        this.setState({
            data: {
                "heading": "Let's connect!",
                "headingText_1": "Experience our product - Request demo",
            },
            contactData: [
                {
                  "id": 1,
                  "listClass": "contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3",
                  "listClass_1": "contact-info color-1 bg-hover active hover-bottom dark-shadow text-center p-5 m-3",
                  "iconClass": "fas fa-mobile-alt fa-3x",
                  "linkClass": "d-block my-2",
                  "title": "+1 (204) 963-2544",
                  "text": "",
                  "click": "tel:+12049632544"
                },
                {
                  "id": 2,
                  "listClass": "contact-info color-3 bg-hover active hover-bottom text-center p-5 m-3",
                  "listClass_1": "contact-info color-3 bg-hover active hover-bottom dark-shadow text-center p-5 m-3",
                  "iconClass": "fas fa-envelope-open-text fa-3x",
                  "linkClass": "d-block my-2",
                  "title": "info@digicheck.ca",
                  "text": "",
                  "click": "email:info@digicheck.ca"
                }
              ]
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading:true, res: ''}); 
        const formData = {
            body:{
                name: this.state.name,
                company_name: this.state.company_name,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message,
            }
        };
        axios.post('https://sru.jdh.mybluehost.me/server/public/api/send-email', formData)
          .then(() => {
            this.setState({loading:false,
              name: '',
              company_name: '',
              email: '',
              phone: '',
              message: '',  
              res: 'Thank you for reaching out to us. We appreciate your request and will respond to you promptly. If your inquiry is urgent, please feel free to call us at 204-963-2544.'}); 
          })
          .catch((error) => {
            console.error(error);
            // Handle error response
          });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
      };

    render() {
      const spinnerClass = `spinner {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #ccc;
        border-top-color: #333;
        animation: spin 1s infinite linear;
      }
      
      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }`;
        return (
            <section id="contact" className="contact-area ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-5">
                            {/* Section Heading */}
                            <div className="section-heading text-center mb-5">
                                <h2>{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                {/* <p className="d-block d-sm-none mt-4">{this.state.data.headingText_2}</p> */}
                            </div>
                            {/* Contact Us */}
                            <div className="contact-us">
                                <ul>
                                    {/* Contact Info */}
                                    {this.state.contactData.map((item, idx) => {
                                        return(
                                            <li key={`co_${idx}`} className={item.listClass}>
                                                <span><i className={item.iconClass} /></span>
                                                <a className={item.linkClass} href={item.click} >
                                                    <h3>{item.title}</h3>
                                                </a>
                                                <p>{item.text}</p>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pt-4 pt-lg-0">
                            {/* Contact Box */}
                            <div className="contact-box text-center">
                                {/* Contact Form */}
                                <form id="contact-form" onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="company_name"
                placeholder="Company Name"
                required
                value={this.state.company_name}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                required
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="phone"
                placeholder="Phone"
                required
                value={this.state.phone}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                placeholder="Message"
                required
                value={this.state.message}
                onChange={this.handleChange}
              ></textarea>
            </div>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-bordered active btn-block mt-3">
              <span className="text-white pr-3">
                <i className="fas fa-paper-plane"></i>
              </span>
              {this.state.btnText}
            </button>
          </div>
        </div>
      </form>
                                {this.state.loading ? <p className="mt-3">  <span className={spinnerClass}></span> Submitting Your Request... </p> : this.state.res ? <p className="text-success mt-3">{this.state.res}</p> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactOne;