import React, { Component } from 'react';

import ScrollupSection from '../Scrollup/Scrollup';
import Header from '../Header/HeaderOne';
import BreadcrumbSection from '../Breadcrumb/BreadcrumbTwo';
import BlogsTwo from './BlogsTwo';
import FooterSection from '../Footer/FooterThree';
import ModalSearchSection from '../Modal/ModalSearch';
import ModalMenuSection from '../Modal/ModalMenu';

class BlogThreeColumn extends Component {
    render() {
        return (
            <div className="blog">
                <ScrollupSection />
                <div className="main overflow-hidden">
                    {/* <Header imageData={"/img/logo-white.png"} /> */}
                    {/* <BreadcrumbSection heading={"Blog Grid"} home={"Home"} page={"Blog Pages"} subpage={"Blog Grid"} title={"Grid 3 Column"} /> */}
                    <BlogsTwo />
                    {/* <FooterSection />
                    <ModalSearchSection />
                    <ModalMenuSection /> */}
                </div>
            </div>
        );
    }
}

export default BlogThreeColumn;