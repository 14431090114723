import React, { Component } from 'react';
import Typewriter from 'typewriter-effect';

const initData = {
    heading: "",
    heading_span: "Revolutionize Your Inspections with Digital Checklists",
    content: "Business Intelligence platform designed to help quality professionals streamline inspection with greater efficiency. With our cloud-based software, you can create and record inspection data digitally on the cloud and export it whenever needed for data exploration and further analysis",
    btnText_1: "Contact Us",
    btnText_2: "View Process"
}

class HeroFour extends Component {
    state = {
        data: {},
        headingAnimationComplete: true
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center align-items-center h-100">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-10">
                            <div className="welcome-intro text-center mt-0 py-5">
                                <h1 className="text-white">{this.state.data.heading} <br />
                                {"Revolutionize Your Inspections With"}
                                <Typewriter
                                    onInit={(typewriter) => {
                                        typewriter.typeString("Checklist")
                                        .callFunction(() => {
                                            this.setState({
                                                headingAnimationComplete: true
                                            })
                                        })
                                        .pauseFor(800)
                                        .deleteAll(30)
                                        .typeString("Digital Checklist")
                                        .pauseFor(1500)
                                        .callFunction(() => {
                                            // console.log('All strings were deleted');
                                        })
                                        .start();
                                    }}
                                    options={{
                                        autoStart: true,
                                        loop: false,
                                      }}
                                    />
                                </h1>
                                {this.state.headingAnimationComplete && <p data-aos="zoom-out" data-aos-delay={800} className="text-white my-4">{this.state.data.content}</p>}
                                {/* Buttons */}
                                {this.state.headingAnimationComplete && <div data-aos="zoom-out" data-aos-delay={800} className="button-group">
                                    <a onClick={()=>{
                                        document.getElementById('contact').scrollIntoView({
                                            behavior: 'smooth'
                                          });
                                    }} className="btn btn-bordered-white">{this.state.data.btnText_1}</a>
                                    <a onClick={()=>{
                                        document.getElementById('viewProcess').scrollIntoView({
                                            behavior: 'smooth'
                                          });
                                    }} className="btn btn-bordered-white d-none d-sm-inline-block">{this.state.data.btnText_2}</a>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroFour;