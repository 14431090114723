import React, { Component } from "react";

import ScrollupSection from "../components/Scrollup/Scrollup";
import Header from "../components/Header/HeaderThree";
import HeroSection from "../components/Hero/HeroFour";
import BrandingSection from "../components/Branding/Branding";
import ContentSection from "../components/Content/ContentOne";
import ContentSectionTwo from "../components/Content/ContentTwo";
import ServiceSection from "../components/Services/ServiceFour";
import PricingSection from "../components/Pricing/PricingTwo";
import ReviewSection from "../components/Reviews/ReviewOne";
import ContactSection from "../components/Contact/ContactOne";
import CtaSection from "../components/Cta/Cta";
import FooterSection from "../components/Footer/FooterOne";
import ModalSearchSection from "../components/Modal/ModalSearch";
import ModalMenuSection from "../components/Modal/ModalMenu";
import ServiceTwo from "../components/Services/ServiceTwo";

class ThemeTen extends Component {
  componentDidMount() {
    // Check if redirectTo location state exists and scroll to the corresponding section
    try {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.redirectTo
      ) {
        const sectionId = this.props.location.state.redirectTo;
        const sectionElement = document.getElementById(sectionId);

        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: "smooth" });
        }
      }
      if (window.AOS) {
        window.AOS.init();
      }
    } catch (error) {
      debugger;
    }
  }

  render() {
    return (
      <div className="homepage-4">
        <ScrollupSection />
        <div className="main overflow-hidden">
          <Header
            logo1={"/img/logo/logo-white.svg"}
            logo2={"/img/logo/logo-blue.svg"}
          />
          <HeroSection />
          <BrandingSection
            initData={{
              heading: "Bringing inspections to the next generation",
              headingText_1:
                "Our platform is designed to make inspections easier, more efficient, and more accurate",
              headingText_2: "",
            }}
            data={[
              {
                id: 1,
                image: "/img/custom/industries/digi1.jpg",
                title: "Cutomize form to your needs",
              },
              {
                id: 3,
                image: "/img/custom/industries/digi2.jpg",
                title: "Record your inspection outcomes",
              },
              {
                id: 4,
                image: "/img/custom/industries/digi3.jpg",
                title: "Publish reports and analyze",
              },
            ]}
          />
          {/* <BrandingSection secType={'about'} /> */}
          <ContentSection
            leftImg={true}
            id="easyCustom"
            data={{
              data: {
                heading:
                  "User-Friendly Form Builder",
                contentThumb: "/img/custom/Artboard 4.png",
              },
              contentData: [
                {
                  id: 1,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "User-friendly interface to create checklists without coding",
                },
                {
                  id: 2,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Wide range of field types, including text fields, checkboxes, radio buttons, drop down menus, file uploads and more",
                },
                {
                  id: 3,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Ability to add conditional logic to your forms",
                },
                {
                  id: 4,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Advanced form validation options",
                },
                {
                  id: 5,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Free checklist library to download and edit forms to suit your need",
                },
              ],
            }}
          />
          <ContentSection
            leftImg={false}
            id="viewProcess"
            data={{
              data: {
                heading: "Digital Part traveler ",
                contentThumb: "/img/custom/Artboard 6.png",
              },
              contentData: [
                {
                  id: 1,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Form integrated communication within crossfunctional teams ",
                },
                {
                  id: 2,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Carry forward part level important notes/events/incidents throughout the production cycle",
                },
                {
                  id: 3,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Photo embedding capability ",
                },
                {
                  id: 4,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Display notes with user details and time stamps",
                },
              ],
            }}
          />
          <ContentSection
            leftImg={true}
            id="realtime"
            data={{
              data: {
                heading: "Real time data monitoring dashboards",
                contentThumb: "/img/custom/Artboard 5.png",
              },
              contentData: [
                {
                  id: 1,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Real time data visualization",
                },
                {
                  id: 2,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Customizable widgets to display key performance indicators (KPIs)",
                },
                {
                  id: 3,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Versatile charts and graphs to monitor trends, historic ups & downs, identify areas of improvements",
                },
                {
                  id: 4,
                  iconClass: "fas fa-angle-double-right",
                  title: "",
                  text: "Ability to export data to PDF or Excel format",
                },
              ],
            }}
          />
          <BrandingSection
            initData={{
              heading: "Bringing inspections to the next generation",
              headingText_1:
                "We serve Manufacturing, Warehousing, Energy & Utilities clients.",
              headingText_2: "",
            }}
            data={[
              {
                id: 1,
                image: "/img/custom/industries/4.jpg",
                title: "Manufacturing",
              },
              {
                id: 3,
                image: "/img/custom/industries/1.jpg",
                title: "Warehousing",
              },
              {
                id: 4,
                image: "/img/custom/industries/3.jpg",
                title: "Energy & Utilities clients",
              },
            ]}
          />
          <ServiceTwo />
          <ContactSection />
          <CtaSection />
          <FooterSection />
          {/* <ModalSearchSection /> */}
          <ModalMenuSection />
        </div>
      </div>
    );
  }
}

export default ThemeTen;
