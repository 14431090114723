import React, { Component } from 'react';

class ServiceTwo extends Component {
    state = {
        data: {},
        serviceData_1: []
    }
    componentDidMount(){
        this.setState({
            serviceData_1: [
                {
                    "id": 1,
                    "iconClass": "flaticon-monitoring color-1 icon-bg-1",
                    "title": "Customized Workflow",
                    "text": "Design and Implement an Optimized Workflow Tailored to Your Needs for Consistency and Standardization in Repetitive Tasks",
                    "btnText": "Learn More"
                },
                {
                    "id": 1,
                    "iconClass": "flaticon-smartphone color-1 icon-bg-1",
                    "title": "Visuals uploading capability ",
                    "text": "Streamline Your Inspection Process with Visual Evidence Collection and Form-Based Visual Cues for Inspectors.",
                    "btnText": "Learn More"
                },
                {
                    "id": 1,
                    "iconClass": "flaticon-affiliate color-1 icon-bg-1",
                    "title": "Business Process management",
                    "text": "Leverage available resources to improve operational efficiency",
                    "btnText": "Learn More"
                },
            ]
        })
    }
    render() {
        return (
            <section id="service" className="section service-area ptb_100 pt-md-0">
                <div className="container">
                    <div className="row">
                        {this.state.serviceData_1.map((item, idx) => {
                            return(
                                <div key={`st_${idx}`} className="col-12 col-md-6 col-lg-4">
                                    {/* Single Service */}
                                    <div className="single-service p-4">
                                        <span className={item.iconClass} />
                                        <h3 className="my-3">{item.title}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceTwo;