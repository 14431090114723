import React, { Component } from "react";

const industryData = [
  {
    id: 1,
    image: "/img/custom/industries/4.jpg",
    title: "Manufacturing",
  },

  {
    id: 3,
    image: "/img/custom/industries/3.jpg",
    title: "Energy and Utilities",
  },
  {
    id: 4,
    image: "/img/custom/industries/1.jpg",
    title: "Warehousing",
  },
];

const dataSec = [
  {
    id: 1,
    image: "/img/custom/industries/about1.png",
    title: "Cutomize your Form",
  },
  {
    id: 3,
    image: "/img/custom/industries/3.jpg",
    title: "Record your inspection outcomes",
  },
  {
    id: 4,
    image: "/img/custom/industries/1.jpg",
    title: "Publish reports and analyze",
  },
];

class BlogSingleTwo extends Component {
  render() {
    const data = this.props.secType ? dataSec : industryData;
    return (
      <div className="row">
        {this.props.data.map((item, idx) => {
          return (
            <div key={`bdo_${idx}`} className="col-12 col-md-6 col-lg-4 mt-5">
              {/* Single Blog */}
              <div className="single-blog res-margin">
                {/* Blog Thumb */}
                <div className="blog-thumb">
                    <img src={item.image} alt="" />
                </div>
                {/* Blog Content */}
                <div className="blog-content">
                  {/* Blog Title */}
                  <h3 className="blog-title my-3">
                    {item.title}
                  </h3>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default BlogSingleTwo;
