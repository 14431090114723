import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const HeaderThree = (props) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <header id="header">
      {/* Navbar */}
      <nav
        data-aos="zoom-out"
        data-aos-delay={800}
        className="navbar navbar-expand"
      >
        <div className="container header">
          {/* Navbar Brand*/}
          <a className="navbar-brand" href="/">
            <img
              className="navbar-brand-regular"
              style={{
                maxWidth: "250px",
                scale: "1.4",
              }}
              src={props.logo1}
              alt="brand-logo"
            />
            <img
              className="navbar-brand-sticky"
              // style={{ maxWidth: "150px" }}
              src={props.logo2}
              style={{
                maxWidth: "150px",
                scale: "2",
              }}
              alt="sticky brand-logo"
            />
          </a>
          <div className="ml-auto" />
          {/* Navbar */}
          <ul className="navbar-nav items">
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => {
                  if (location.pathname === "/") {
                    const sectionElement = document.getElementById("home");
                    if (sectionElement) {
                      sectionElement.scrollIntoView({ behavior: "smooth" });
                    }
                  } else {
                    history.push("/", {
                      redirectTo: "home",
                    });
                  }
                }}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link "
                onClick={() => {
                  if (location.pathname === "/") {
                    const sectionElement = document.getElementById("about");
                    if (sectionElement) {
                      sectionElement.scrollIntoView({ behavior: "smooth" });
                    }
                  } else {
                    history.push("/", {
                      redirectTo: "about",
                    });
                  }
                }}
              >
                About
              </a>
            </li>
            {/* <li className="nav-item">
                                <a className="nav-link scroll" href="#services">Services</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" href="#portfolio">Portfolio</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" href="#pricing">Pricing</a>
                            </li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => {
                  if (location.pathname === "/") {
                    const sectionElement = document.getElementById("contact");
                    if (sectionElement) {
                      sectionElement.scrollIntoView({ behavior: "smooth" });
                    }
                    window.scrollTo("#contact");
                  } else {
                    history.push("/", {
                      redirectTo: "contact",
                    });
                  }
                }}
              >
                Contact
              </a>
            </li>
          </ul>
          {/* Navbar Icons */}
          <ul className="navbar-nav icons" style={{ display: "none" }}>
            <li className="nav-item">
              <a
                href="/#"
                className="nav-link"
                data-toggle="modal"
                data-target="#search"
              >
                <i className="fas fa-search" />
              </a>
            </li>
            <li className="nav-item social">
              <a href="/#" className="nav-link">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li className="nav-item social">
              <a href="/#" className="nav-link">
                <i className="fab fa-twitter" />
              </a>
            </li>
          </ul>
          {/* Navbar Toggler */}
          <ul className="navbar-nav toggle">
            <li className="nav-item">
              <a
                href="/#"
                className="nav-link"
                data-toggle="modal"
                data-target="#menu"
              >
                <i className="fas fa-bars toggle-icon m-0" />
              </a>
            </li>
          </ul>
          {/* Navbar Action Button */}
          <ul className="navbar-nav action">
            <li className="nav-item ml-3">
              <a
                href="https://app.digicheck.ca/login"
                target="_blank"
                className="btn ml-lg-auto btn-bordered-white"
              >
                Login
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default HeaderThree;
