import React, { Component } from 'react';

class HeaderFour extends Component {
    render() {
        return (
            <header id="header">
                {/* Navbar */}
                <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand navbar-light">
                    <div className="container header">
                    {/* Navbar Brand*/}
                    <a className="navbar-brand" href="/">
                        <img src={this.props.imageData} style={{
                            width:"100%"
                        }} alt="sticky brand-logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar */}
                    <ul className="navbar-nav items">
                        <li className="nav-item">
                        <a className="nav-link scroll" href="#home">Home</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link scroll" href="#about">About</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link scroll" href="#services">Services</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link scroll" href="#portfolio">Portfolio</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link scroll" href="#pricing">Pricing</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link scroll" href="#contact">Contact</a>
                        </li>
                    </ul>
                    {/* Navbar Icons */}
                    <ul className="navbar-nav icons">
                        <li className="nav-item">
                        <a href="/#" className="nav-link" data-toggle="modal" data-target="#search">
                            <i className="fas fa-search" />
                        </a>
                        </li>
                        <li className="nav-item social">
                        <a href="/#" className="nav-link"><i className="fab fa-facebook-f" /></a>
                        </li>
                        <li className="nav-item social">
                        <a href="/#" className="nav-link"><i className="fab fa-twitter" /></a>
                        </li>
                    </ul>
                    {/* Navbar Toggler */}
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                        <a href="/#" className="nav-link" data-toggle="modal" data-target="#menu">
                            <i className="fas fa-bars toggle-icon m-0" />
                        </a>
                        </li>
                    </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

export default HeaderFour;